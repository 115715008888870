import './App.css';
import terraPoolLogo from './Assets/logo-small.png'
import terraPoolBg from './Assets/bg.webp'

function App() {
  return (
    <div className="App" style={{
      backgroundImage: `url(${terraPoolBg})`, 
      height: window.innerHeight,
      backgroundPosition: 'center', 
      backgroundRepeat: 'no-repeat', 
      backgroundSize: 'cover'}}>
      <div className="content">
        <img src={terraPoolLogo} alt="logo" className="terraPoollogo"/>
        <h1 className="contentTitle">THE WORLD’S FIRST <br className="mobile-break" />BITCOIN MINING POOL <br className="mobile-break" />POWERED BY CLEAN ENERGY</h1>
        <p>Terra Pool will provide both a strong incentive and accessible platform for cryptocurrency miners to produce Bitcoin in a sustainable and climate-conscious way with the goal of significantly reducing greenhouse gas emissions over the next decade. 
          In the near-term, Terra Pool will work with like-minded Bitcoin miners to expedite the shift from conventional power to clean energy.</p>
        <p>Terra Pool pursues a corporate policy that views technical progress and environmental protection not as a contradiction but as an inseparable whole.</p>
        <p>GREEN. POOLED. TECHNOLOGY.</p>
      </div>      
    </div>
  );
}

export default App;
